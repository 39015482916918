import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import * as Styled from './slpWelcomeMarketoFormStyles';
import { superScriptChars } from '../../constants/superScriptChars';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const SlpMarketoForm = ({ sectionData }) => {
  async function initMarktoForm() {
    // eslint-disable-next-line no-undef
    await Munchkin.init(parseInt(process.env.GATSBY_MARKETO_ID));
  }
  React.useEffect(() => {
    initMarktoForm();
    // eslint-disable-next-line no-undef, no-unused-expressions
    MktoForms2?.loadForm(
      process.env.GATSBY_MARKETO_URL, //string
      process.env.GATSBY_MARKETO_ID, //number
      sectionData?.subText //string
    );
  }, []);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.Header2
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          {children}
        </Styled.Header2>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
    },
    renderText: (text) =>
      text
        .split('\n')
        .flatMap((text, i) => [
          i > 0 && <br key={i} />,
          <React.Fragment key={i}>
            {text
              ?.split('')
              ?.map((i, k) =>
                superScriptChars.includes(i) ? (
                  <Styled.SuperScriptStyle key={k}>{i}</Styled.SuperScriptStyle>
                ) : (
                  <React.Fragment key={k}>{i}</React.Fragment>
                )
              )}
          </React.Fragment>,
        ]),
  };
  if (typeof window === 'undefined') {
    return null;
  } else {
    return (
      <>
        <Styled.Body id={sectionData.elementId}>
          {sectionData.contentDetails &&
            documentToReactComponents(
              JSON.parse(sectionData.contentDetails.raw),
              optionsMainStyle
            )}
          <Styled.FormBody className="d-flex justify-content-center">
            {sectionData?.header && <form id={sectionData?.header?.trim()}></form>}
          </Styled.FormBody>
        </Styled.Body>
      </>
    );
  };
};

export default SlpMarketoForm;
